/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { ConfirmOrderRequestRequest } from '../model/confirmOrderRequestRequest'
// @ts-ignore
import { CouponDetailCartOrder } from '../model/couponDetailCartOrder'
// @ts-ignore
import { EditOrderRequest } from '../model/editOrderRequest'
// @ts-ignore
import { EditOrderRequestRequest } from '../model/editOrderRequestRequest'
// @ts-ignore
import { GeneralTradeOrderDetailGet } from '../model/generalTradeOrderDetailGet'
// @ts-ignore
import { MinimumProductPackageWithPromotion } from '../model/minimumProductPackageWithPromotion'
// @ts-ignore
import { OrderDetailGet } from '../model/orderDetailGet'
// @ts-ignore
import { OrderDetailGetRequest } from '../model/orderDetailGetRequest'
// @ts-ignore
import { PaginatedGeneralTradeOrderListList } from '../model/paginatedGeneralTradeOrderListList'
// @ts-ignore
import { PaginatedMinimumProductPackageWithPromotionList } from '../model/paginatedMinimumProductPackageWithPromotionList'
// @ts-ignore
import { PaginatedOrderListList } from '../model/paginatedOrderListList'
// @ts-ignore
import { PreCalcOrderSerializerRequestRequest } from '../model/preCalcOrderSerializerRequestRequest'
// @ts-ignore
import { PreCalcOrderSerializerResponse } from '../model/preCalcOrderSerializerResponse'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

export interface OrdersCouponsListRequestParams {
  orderId: number
}

export interface OrdersCouponsProductPackagesListRequestParams {
  couponId: number
  orderId: number
  /** The pagination cursor value. */
  cursor?: string
  /** Number of results to return per page. */
  pageSize?: number
}

export interface OrdersCouponsProductPackagesRetrieveRequestParams {
  couponId: number
  id: string
  orderId: number
}

export interface OrdersCouponsRetrieveRequestParams {
  /** A unique integer value identifying this coupon. */
  id: number
  orderId: number
}

export interface OrdersGeneralTradeAcceptedDeliveredOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersGeneralTradeApproveWsEditOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersGeneralTradeCancelOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersGeneralTradeListRequestParams {
  /** The pagination cursor value. */
  cursor?: string
  id?: number
  /** * &#x60;PENDING_WS_CONFIRMATION&#x60; - Pending Ws Confirmation * &#x60;PENDING_GT_CONFIRMATION&#x60; - Pending Gt Confirmation * &#x60;PREPARING_ORDER&#x60; - Preparing Order * &#x60;OUT_FOR_DELIVERY&#x60; - Out For Delivery * &#x60;READY_FOR_PICK_UP&#x60; - Ready For Pick Up * &#x60;PENDING_GT_ACCEPT&#x60; - Pending Gt Accept * &#x60;SUCCESS&#x60; - Success * &#x60;CANCELLED&#x60; - Cancelled */
  orderStatus?:
    | 'CANCELLED'
    | 'OUT_FOR_DELIVERY'
    | 'PENDING_GT_ACCEPT'
    | 'PENDING_GT_CONFIRMATION'
    | 'PENDING_WS_CONFIRMATION'
    | 'PREPARING_ORDER'
    | 'READY_FOR_PICK_UP'
    | 'SUCCESS'
  /** Number of results to return per page. */
  pageSize?: number
  /** A search term. */
  search?: string
}

export interface OrdersGeneralTradeReceiveCouponRetrieveRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersGeneralTradeReorderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersGeneralTradeRetrieveRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersPrintOrderRetrieveRequestParams {
  orderId: number
}

export interface OrdersPrintOrderThermalRetrieveRequestParams {
  orderId: number
}

export interface OrdersWholesaleAcceptedDeliveredOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersWholesaleCancelOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersWholesaleConfirmOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
  confirmOrderRequestRequest: ConfirmOrderRequestRequest
}

export interface OrdersWholesaleCreateRequestParams {
  orderDetailGetRequest: OrderDetailGetRequest
}

export interface OrdersWholesaleDeliveredCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersWholesaleFinishPreparingOrderCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersWholesaleListRequestParams {
  /** The pagination cursor value. */
  cursor?: string
  id?: number
  /** * &#x60;PENDING_WS_CONFIRMATION&#x60; - Pending Ws Confirmation * &#x60;PENDING_GT_CONFIRMATION&#x60; - Pending Gt Confirmation * &#x60;PREPARING_ORDER&#x60; - Preparing Order * &#x60;OUT_FOR_DELIVERY&#x60; - Out For Delivery * &#x60;READY_FOR_PICK_UP&#x60; - Ready For Pick Up * &#x60;PENDING_GT_ACCEPT&#x60; - Pending Gt Accept * &#x60;SUCCESS&#x60; - Success * &#x60;CANCELLED&#x60; - Cancelled */
  orderStatus?:
    | 'CANCELLED'
    | 'OUT_FOR_DELIVERY'
    | 'PENDING_GT_ACCEPT'
    | 'PENDING_GT_CONFIRMATION'
    | 'PENDING_WS_CONFIRMATION'
    | 'PREPARING_ORDER'
    | 'READY_FOR_PICK_UP'
    | 'SUCCESS'
  /** Number of results to return per page. */
  pageSize?: number
  /** A search term. */
  search?: string
}

export interface OrdersWholesalePrecalcPriceCreateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
  preCalcOrderSerializerRequestRequest: PreCalcOrderSerializerRequestRequest
}

export interface OrdersWholesaleRetrieveRequestParams {
  /** A unique integer value identifying this order. */
  id: number
}

export interface OrdersWholesaleUpdateRequestParams {
  /** A unique integer value identifying this order. */
  id: number
  editOrderRequestRequest: EditOrderRequestRequest
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  protected basePath = 'http://localhost'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()
  public encoder: HttpParameterCodec

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0]
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath
      }
      this.configuration.basePath = basePath
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value)
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
    }
    return httpParams
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        ;(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)))
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10))
        } else {
          throw Error('key may not be null if value is Date')
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        )
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value)
    } else {
      throw Error('key may not be null if value is not object or array')
    }
    return httpParams
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersCouponsList(
    requestParameters: OrdersCouponsListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<CouponDetailCartOrder>>
  public ordersCouponsList(
    requestParameters: OrdersCouponsListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<CouponDetailCartOrder>>>
  public ordersCouponsList(
    requestParameters: OrdersCouponsListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<CouponDetailCartOrder>>>
  public ordersCouponsList(
    requestParameters: OrdersCouponsListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling ordersCouponsList.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/coupons/`
    return this.httpClient.request<Array<CouponDetailCartOrder>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersCouponsProductPackagesList(
    requestParameters: OrdersCouponsProductPackagesListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedMinimumProductPackageWithPromotionList>
  public ordersCouponsProductPackagesList(
    requestParameters: OrdersCouponsProductPackagesListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>
  public ordersCouponsProductPackagesList(
    requestParameters: OrdersCouponsProductPackagesListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>
  public ordersCouponsProductPackagesList(
    requestParameters: OrdersCouponsProductPackagesListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const couponId = requestParameters.couponId
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling ordersCouponsProductPackagesList.',
      )
    }
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling ordersCouponsProductPackagesList.')
    }
    const cursor = requestParameters.cursor
    const pageSize = requestParameters.pageSize

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cursor, 'cursor')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/coupons/${this.configuration.encodeParam({
      name: 'couponId',
      value: couponId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/product-packages/`
    return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersCouponsProductPackagesRetrieve(
    requestParameters: OrdersCouponsProductPackagesRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<MinimumProductPackageWithPromotion>
  public ordersCouponsProductPackagesRetrieve(
    requestParameters: OrdersCouponsProductPackagesRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<MinimumProductPackageWithPromotion>>
  public ordersCouponsProductPackagesRetrieve(
    requestParameters: OrdersCouponsProductPackagesRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<MinimumProductPackageWithPromotion>>
  public ordersCouponsProductPackagesRetrieve(
    requestParameters: OrdersCouponsProductPackagesRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const couponId = requestParameters.couponId
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling ordersCouponsProductPackagesRetrieve.',
      )
    }
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersCouponsProductPackagesRetrieve.')
    }
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling ordersCouponsProductPackagesRetrieve.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/coupons/${this.configuration.encodeParam({
      name: 'couponId',
      value: couponId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/product-packages/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<MinimumProductPackageWithPromotion>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersCouponsRetrieve(
    requestParameters: OrdersCouponsRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<CouponDetailCartOrder>
  public ordersCouponsRetrieve(
    requestParameters: OrdersCouponsRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<CouponDetailCartOrder>>
  public ordersCouponsRetrieve(
    requestParameters: OrdersCouponsRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<CouponDetailCartOrder>>
  public ordersCouponsRetrieve(
    requestParameters: OrdersCouponsRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersCouponsRetrieve.')
    }
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling ordersCouponsRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/coupons/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<CouponDetailCartOrder>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeAcceptedDeliveredOrderCreate(
    requestParameters: OrdersGeneralTradeAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersGeneralTradeAcceptedDeliveredOrderCreate(
    requestParameters: OrdersGeneralTradeAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersGeneralTradeAcceptedDeliveredOrderCreate(
    requestParameters: OrdersGeneralTradeAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersGeneralTradeAcceptedDeliveredOrderCreate(
    requestParameters: OrdersGeneralTradeAcceptedDeliveredOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling ordersGeneralTradeAcceptedDeliveredOrderCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/accepted-delivered-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeApproveWsEditOrderCreate(
    requestParameters: OrdersGeneralTradeApproveWsEditOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersGeneralTradeApproveWsEditOrderCreate(
    requestParameters: OrdersGeneralTradeApproveWsEditOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersGeneralTradeApproveWsEditOrderCreate(
    requestParameters: OrdersGeneralTradeApproveWsEditOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersGeneralTradeApproveWsEditOrderCreate(
    requestParameters: OrdersGeneralTradeApproveWsEditOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling ordersGeneralTradeApproveWsEditOrderCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/approve-ws-edit-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeCancelOrderCreate(
    requestParameters: OrdersGeneralTradeCancelOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersGeneralTradeCancelOrderCreate(
    requestParameters: OrdersGeneralTradeCancelOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersGeneralTradeCancelOrderCreate(
    requestParameters: OrdersGeneralTradeCancelOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersGeneralTradeCancelOrderCreate(
    requestParameters: OrdersGeneralTradeCancelOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersGeneralTradeCancelOrderCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/cancel-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeList(
    requestParameters: OrdersGeneralTradeListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedGeneralTradeOrderListList>
  public ordersGeneralTradeList(
    requestParameters: OrdersGeneralTradeListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedGeneralTradeOrderListList>>
  public ordersGeneralTradeList(
    requestParameters: OrdersGeneralTradeListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedGeneralTradeOrderListList>>
  public ordersGeneralTradeList(
    requestParameters: OrdersGeneralTradeListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const cursor = requestParameters.cursor
    const id = requestParameters.id
    const orderStatus = requestParameters.orderStatus
    const pageSize = requestParameters.pageSize
    const search = requestParameters.search

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cursor, 'cursor')
    }
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'id')
    }
    if (orderStatus !== undefined && orderStatus !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderStatus, 'order_status')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/`
    return this.httpClient.request<PaginatedGeneralTradeOrderListList>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeReceiveCouponRetrieve(
    requestParameters: OrdersGeneralTradeReceiveCouponRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersGeneralTradeReceiveCouponRetrieve(
    requestParameters: OrdersGeneralTradeReceiveCouponRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersGeneralTradeReceiveCouponRetrieve(
    requestParameters: OrdersGeneralTradeReceiveCouponRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersGeneralTradeReceiveCouponRetrieve(
    requestParameters: OrdersGeneralTradeReceiveCouponRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling ordersGeneralTradeReceiveCouponRetrieve.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/receive-coupon/`
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeReorderCreate(
    requestParameters: OrdersGeneralTradeReorderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersGeneralTradeReorderCreate(
    requestParameters: OrdersGeneralTradeReorderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersGeneralTradeReorderCreate(
    requestParameters: OrdersGeneralTradeReorderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersGeneralTradeReorderCreate(
    requestParameters: OrdersGeneralTradeReorderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersGeneralTradeReorderCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/reorder/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersGeneralTradeRetrieve(
    requestParameters: OrdersGeneralTradeRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<GeneralTradeOrderDetailGet>
  public ordersGeneralTradeRetrieve(
    requestParameters: OrdersGeneralTradeRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<GeneralTradeOrderDetailGet>>
  public ordersGeneralTradeRetrieve(
    requestParameters: OrdersGeneralTradeRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<GeneralTradeOrderDetailGet>>
  public ordersGeneralTradeRetrieve(
    requestParameters: OrdersGeneralTradeRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersGeneralTradeRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/general-trade/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<GeneralTradeOrderDetailGet>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersPrintOrderRetrieve(
    requestParameters: OrdersPrintOrderRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Blob>
  public ordersPrintOrderRetrieve(
    requestParameters: OrdersPrintOrderRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Blob>>
  public ordersPrintOrderRetrieve(
    requestParameters: OrdersPrintOrderRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Blob>>
  public ordersPrintOrderRetrieve(
    requestParameters: OrdersPrintOrderRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling ordersPrintOrderRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/print-order/`
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersPrintOrderThermalRetrieve(
    requestParameters: OrdersPrintOrderThermalRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Blob>
  public ordersPrintOrderThermalRetrieve(
    requestParameters: OrdersPrintOrderThermalRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Blob>>
  public ordersPrintOrderThermalRetrieve(
    requestParameters: OrdersPrintOrderThermalRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Blob>>
  public ordersPrintOrderThermalRetrieve(
    requestParameters: OrdersPrintOrderThermalRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const orderId = requestParameters.orderId
    if (orderId === null || orderId === undefined) {
      throw new Error('Required parameter orderId was null or undefined when calling ordersPrintOrderThermalRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let localVarPath = `/v1/orders/${this.configuration.encodeParam({
      name: 'orderId',
      value: orderId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/print-order-thermal/`
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleAcceptedDeliveredOrderCreate(
    requestParameters: OrdersWholesaleAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersWholesaleAcceptedDeliveredOrderCreate(
    requestParameters: OrdersWholesaleAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersWholesaleAcceptedDeliveredOrderCreate(
    requestParameters: OrdersWholesaleAcceptedDeliveredOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersWholesaleAcceptedDeliveredOrderCreate(
    requestParameters: OrdersWholesaleAcceptedDeliveredOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling ordersWholesaleAcceptedDeliveredOrderCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/accepted-delivered-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleCancelOrderCreate(
    requestParameters: OrdersWholesaleCancelOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersWholesaleCancelOrderCreate(
    requestParameters: OrdersWholesaleCancelOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersWholesaleCancelOrderCreate(
    requestParameters: OrdersWholesaleCancelOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersWholesaleCancelOrderCreate(
    requestParameters: OrdersWholesaleCancelOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesaleCancelOrderCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/cancel-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleConfirmOrderCreate(
    requestParameters: OrdersWholesaleConfirmOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersWholesaleConfirmOrderCreate(
    requestParameters: OrdersWholesaleConfirmOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersWholesaleConfirmOrderCreate(
    requestParameters: OrdersWholesaleConfirmOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersWholesaleConfirmOrderCreate(
    requestParameters: OrdersWholesaleConfirmOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesaleConfirmOrderCreate.')
    }
    const confirmOrderRequestRequest = requestParameters.confirmOrderRequestRequest
    if (confirmOrderRequestRequest === null || confirmOrderRequestRequest === undefined) {
      throw new Error(
        'Required parameter confirmOrderRequestRequest was null or undefined when calling ordersWholesaleConfirmOrderCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/confirm-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: confirmOrderRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleCreate(
    requestParameters: OrdersWholesaleCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<OrderDetailGet>
  public ordersWholesaleCreate(
    requestParameters: OrdersWholesaleCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<OrderDetailGet>>
  public ordersWholesaleCreate(
    requestParameters: OrdersWholesaleCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<OrderDetailGet>>
  public ordersWholesaleCreate(
    requestParameters: OrdersWholesaleCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const orderDetailGetRequest = requestParameters.orderDetailGetRequest
    if (orderDetailGetRequest === null || orderDetailGetRequest === undefined) {
      throw new Error(
        'Required parameter orderDetailGetRequest was null or undefined when calling ordersWholesaleCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/`
    return this.httpClient.request<OrderDetailGet>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: orderDetailGetRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleDeliveredCreate(
    requestParameters: OrdersWholesaleDeliveredCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersWholesaleDeliveredCreate(
    requestParameters: OrdersWholesaleDeliveredCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersWholesaleDeliveredCreate(
    requestParameters: OrdersWholesaleDeliveredCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersWholesaleDeliveredCreate(
    requestParameters: OrdersWholesaleDeliveredCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesaleDeliveredCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/delivered/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleFinishPreparingOrderCreate(
    requestParameters: OrdersWholesaleFinishPreparingOrderCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public ordersWholesaleFinishPreparingOrderCreate(
    requestParameters: OrdersWholesaleFinishPreparingOrderCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public ordersWholesaleFinishPreparingOrderCreate(
    requestParameters: OrdersWholesaleFinishPreparingOrderCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public ordersWholesaleFinishPreparingOrderCreate(
    requestParameters: OrdersWholesaleFinishPreparingOrderCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling ordersWholesaleFinishPreparingOrderCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/finish-preparing-order/`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleList(
    requestParameters: OrdersWholesaleListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedOrderListList>
  public ordersWholesaleList(
    requestParameters: OrdersWholesaleListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedOrderListList>>
  public ordersWholesaleList(
    requestParameters: OrdersWholesaleListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedOrderListList>>
  public ordersWholesaleList(
    requestParameters: OrdersWholesaleListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const cursor = requestParameters.cursor
    const id = requestParameters.id
    const orderStatus = requestParameters.orderStatus
    const pageSize = requestParameters.pageSize
    const search = requestParameters.search

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cursor, 'cursor')
    }
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'id')
    }
    if (orderStatus !== undefined && orderStatus !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderStatus, 'order_status')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/`
    return this.httpClient.request<PaginatedOrderListList>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesalePrecalcPriceCreate(
    requestParameters: OrdersWholesalePrecalcPriceCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PreCalcOrderSerializerResponse>
  public ordersWholesalePrecalcPriceCreate(
    requestParameters: OrdersWholesalePrecalcPriceCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PreCalcOrderSerializerResponse>>
  public ordersWholesalePrecalcPriceCreate(
    requestParameters: OrdersWholesalePrecalcPriceCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PreCalcOrderSerializerResponse>>
  public ordersWholesalePrecalcPriceCreate(
    requestParameters: OrdersWholesalePrecalcPriceCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesalePrecalcPriceCreate.')
    }
    const preCalcOrderSerializerRequestRequest = requestParameters.preCalcOrderSerializerRequestRequest
    if (preCalcOrderSerializerRequestRequest === null || preCalcOrderSerializerRequestRequest === undefined) {
      throw new Error(
        'Required parameter preCalcOrderSerializerRequestRequest was null or undefined when calling ordersWholesalePrecalcPriceCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/precalc-price/`
    return this.httpClient.request<PreCalcOrderSerializerResponse>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: preCalcOrderSerializerRequestRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleRetrieve(
    requestParameters: OrdersWholesaleRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<OrderDetailGet>
  public ordersWholesaleRetrieve(
    requestParameters: OrdersWholesaleRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<OrderDetailGet>>
  public ordersWholesaleRetrieve(
    requestParameters: OrdersWholesaleRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<OrderDetailGet>>
  public ordersWholesaleRetrieve(
    requestParameters: OrdersWholesaleRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesaleRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<OrderDetailGet>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ordersWholesaleUpdate(
    requestParameters: OrdersWholesaleUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<EditOrderRequest>
  public ordersWholesaleUpdate(
    requestParameters: OrdersWholesaleUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<EditOrderRequest>>
  public ordersWholesaleUpdate(
    requestParameters: OrdersWholesaleUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<EditOrderRequest>>
  public ordersWholesaleUpdate(
    requestParameters: OrdersWholesaleUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling ordersWholesaleUpdate.')
    }
    const editOrderRequestRequest = requestParameters.editOrderRequestRequest
    if (editOrderRequestRequest === null || editOrderRequestRequest === undefined) {
      throw new Error(
        'Required parameter editOrderRequestRequest was null or undefined when calling ordersWholesaleUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/orders/wholesale/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<EditOrderRequest>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editOrderRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }
}
