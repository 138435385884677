/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `ACTIVE` - Active * `EXPIRED` - Expired * `UPCOMING` - Upcoming
 */
export type PromotionStatusEnum = 'ACTIVE' | 'EXPIRED' | 'UPCOMING'

export const PromotionStatusEnum = {
  Active: 'ACTIVE' as PromotionStatusEnum,
  Expired: 'EXPIRED' as PromotionStatusEnum,
  Upcoming: 'UPCOMING' as PromotionStatusEnum,
}
