import { ChangeDetectionStrategy, Component, ElementRef, Input, NgModule } from '@angular/core'
import { iconList } from '../icon/icon-list'

@Component({
  selector: 'app-icon',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  set name(iconName: string) {
    const iconSvg = iconList[iconName]
    this.element.nativeElement.innerHTML = '' // Reset icon
    if (iconSvg) {
      this.element.nativeElement.innerHTML = `<i class="anticon">${iconSvg}</i>`
      // Add necessary style, since css class wont work when injecting html dynamically (css encapsulation?)
      const iElement = this.element.nativeElement.children[0]
      // Drag the icon down to baseline
      iElement.style.top = '.05em'
      iElement.style.position = 'relative'
      const svgElement = iElement.children[0]
      // This allows the icon to change its size based on the text to it, or <i> fontsize
      svgElement.style.width = '1em'
      svgElement.style.height = '1em'

      if (this.currentSize || this.currentSize === 0) {
        // If currentSize exist, also set the new icon with such size.
        this.setIconFontSize(this.currentSize)
      }
    }
  }

  currentSize?: number
  @Input()
  set size(_size: number) {
    this.currentSize = _size
    this.setIconFontSize(_size)
  }

  constructor(public element: ElementRef) {}

  setIconFontSize(size: number): void {
    if (!this.element.nativeElement.children[0]) {
      return
    }
    this.element.nativeElement.children[0].style.fontSize = `${size}px`
  }

  @Input()
  set ignoreBaseline(ignoreBaseline: boolean) {
    if (this.element.nativeElement.children[0] && ignoreBaseline) {
      this.element.nativeElement.children[0].style.top = `0`
    }
  }
}

@NgModule({
  declarations: [IconComponent],
  imports: [],
  exports: [IconComponent],
})
export class IconModule {}
