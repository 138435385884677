import { Injectable } from '@angular/core'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { SpinnerOverlayComponent } from '@shared/components/v1/spinner-overlay/spinner-overlay.component'

@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  private overlayRef!: OverlayRef

  constructor(private overlay: Overlay) {}

  public show() {
    // Returns an OverlayRef (which is a PortalHost)
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create()
    }

    if (this.overlayRef.hasAttached()) {
      return
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent)
    this.overlayRef.attach(spinnerOverlayPortal) // Attach ComponentPortal to PortalHost
  }

  public hide() {
    if (this.overlayRef) {
      this.overlayRef.detach()
    }
  }
}
