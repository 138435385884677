/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { ChangePasswordRequest } from '../model/changePasswordRequest'
// @ts-ignore
import { District } from '../model/district'
// @ts-ignore
import { Email } from '../model/email'
// @ts-ignore
import { EmailRequest } from '../model/emailRequest'
// @ts-ignore
import { LoginRequest } from '../model/loginRequest'
// @ts-ignore
import { Me } from '../model/me'
// @ts-ignore
import { NewGTUserCheckoutRegister } from '../model/newGTUserCheckoutRegister'
// @ts-ignore
import { NewGTUserCheckoutRegisterRequest } from '../model/newGTUserCheckoutRegisterRequest'
// @ts-ignore
import { NewGTUserPhoneOnly } from '../model/newGTUserPhoneOnly'
// @ts-ignore
import { NewGTUserPhoneOnlyRequest } from '../model/newGTUserPhoneOnlyRequest'
// @ts-ignore
import { OTPRequestResponse } from '../model/oTPRequestResponse'
// @ts-ignore
import { OTPVerifyRequestRequest } from '../model/oTPVerifyRequestRequest'
// @ts-ignore
import { PasswordToken } from '../model/passwordToken'
// @ts-ignore
import { PasswordTokenRequest } from '../model/passwordTokenRequest'
// @ts-ignore
import { Province } from '../model/province'
// @ts-ignore
import { ResetToken } from '../model/resetToken'
// @ts-ignore
import { ResetTokenRequest } from '../model/resetTokenRequest'
// @ts-ignore
import { SubDistrict } from '../model/subDistrict'
// @ts-ignore
import { TokenObtainPair } from '../model/tokenObtainPair'
// @ts-ignore
import { TokenObtainPairRequest } from '../model/tokenObtainPairRequest'
// @ts-ignore
import { TokenRefresh } from '../model/tokenRefresh'
// @ts-ignore
import { TokenRefreshRequest } from '../model/tokenRefreshRequest'
// @ts-ignore
import { TokenResponse } from '../model/tokenResponse'
// @ts-ignore
import { User } from '../model/user'
// @ts-ignore
import { UserAddressInfo } from '../model/userAddressInfo'
// @ts-ignore
import { UserProfileGet } from '../model/userProfileGet'
// @ts-ignore
import { UserProfileUpdateRequest } from '../model/userProfileUpdateRequest'
// @ts-ignore
import { UserRequest } from '../model/userRequest'
// @ts-ignore
import { ZipCode } from '../model/zipCode'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

export interface UsersLoginCreateRequestParams {
  loginRequest: LoginRequest
}

export interface UsersLoginWithPasswordCreateRequestParams {
  tokenObtainPairRequest: TokenObtainPairRequest
}

export interface UsersOtpVerifyCreateRequestParams {
  oTPVerifyRequestRequest: OTPVerifyRequestRequest
}

export interface UsersPasswordResetConfirmCreateRequestParams {
  passwordTokenRequest: PasswordTokenRequest
}

export interface UsersPasswordResetCreateRequestParams {
  emailRequest: EmailRequest
}

export interface UsersPasswordResetValidateTokenCreateRequestParams {
  resetTokenRequest: ResetTokenRequest
}

export interface UsersProfileChangePasswordCreateRequestParams {
  changePasswordRequest: ChangePasswordRequest
}

export interface UsersProfileUpdateRequestParams {
  userProfileUpdateRequest: UserProfileUpdateRequest
}

export interface UsersRegisterWhenCheckoutUpdateRequestParams {
  /** A unique integer value identifying this user. */
  id: number
  newGTUserCheckoutRegisterRequest?: NewGTUserCheckoutRegisterRequest
}

export interface UsersRegisterWithPhoneNumberUpdateRequestParams {
  /** A unique integer value identifying this user. */
  id: number
  newGTUserPhoneOnlyRequest: NewGTUserPhoneOnlyRequest
}

export interface UsersRetrieveRequestParams {
  /** A unique integer value identifying this user. */
  id: number
}

export interface UsersTokenRefreshCreateRequestParams {
  tokenRefreshRequest: TokenRefreshRequest
}

export interface UsersUpdateRequestParams {
  /** A unique integer value identifying this user. */
  id: number
  userRequest: UserRequest
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  protected basePath = 'http://localhost'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()
  public encoder: HttpParameterCodec

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0]
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath
      }
      this.configuration.basePath = basePath
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value)
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
    }
    return httpParams
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        ;(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)))
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10))
        } else {
          throw Error('key may not be null if value is Date')
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        )
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value)
    } else {
      throw Error('key may not be null if value is not object or array')
    }
    return httpParams
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersAddressesDistrictsList(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<District>>
  public usersAddressesDistrictsList(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<District>>>
  public usersAddressesDistrictsList(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<District>>>
  public usersAddressesDistrictsList(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/addresses/districts/`
    return this.httpClient.request<Array<District>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersAddressesList(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<ZipCode>>
  public usersAddressesList(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<ZipCode>>>
  public usersAddressesList(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<ZipCode>>>
  public usersAddressesList(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/addresses/`
    return this.httpClient.request<Array<ZipCode>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersAddressesProvincesList(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<Province>>
  public usersAddressesProvincesList(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<Province>>>
  public usersAddressesProvincesList(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<Province>>>
  public usersAddressesProvincesList(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/addresses/provinces/`
    return this.httpClient.request<Array<Province>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersAddressesSubDistrictsList(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<SubDistrict>>
  public usersAddressesSubDistrictsList(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<SubDistrict>>>
  public usersAddressesSubDistrictsList(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<SubDistrict>>>
  public usersAddressesSubDistrictsList(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/addresses/sub-districts/`
    return this.httpClient.request<Array<SubDistrict>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersLoginCreate(
    requestParameters: UsersLoginCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<TokenResponse>
  public usersLoginCreate(
    requestParameters: UsersLoginCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<TokenResponse>>
  public usersLoginCreate(
    requestParameters: UsersLoginCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<TokenResponse>>
  public usersLoginCreate(
    requestParameters: UsersLoginCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const loginRequest = requestParameters.loginRequest
    if (loginRequest === null || loginRequest === undefined) {
      throw new Error('Required parameter loginRequest was null or undefined when calling usersLoginCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/login`
    return this.httpClient.request<TokenResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: loginRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersLoginWithPasswordCreate(
    requestParameters: UsersLoginWithPasswordCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<TokenObtainPair>
  public usersLoginWithPasswordCreate(
    requestParameters: UsersLoginWithPasswordCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<TokenObtainPair>>
  public usersLoginWithPasswordCreate(
    requestParameters: UsersLoginWithPasswordCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<TokenObtainPair>>
  public usersLoginWithPasswordCreate(
    requestParameters: UsersLoginWithPasswordCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const tokenObtainPairRequest = requestParameters.tokenObtainPairRequest
    if (tokenObtainPairRequest === null || tokenObtainPairRequest === undefined) {
      throw new Error(
        'Required parameter tokenObtainPairRequest was null or undefined when calling usersLoginWithPasswordCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/login-with-password`
    return this.httpClient.request<TokenObtainPair>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: tokenObtainPairRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersMeRetrieve(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Me>
  public usersMeRetrieve(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Me>>
  public usersMeRetrieve(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Me>>
  public usersMeRetrieve(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/me`
    return this.httpClient.request<Me>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersMyAddressRetrieve(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<UserAddressInfo>
  public usersMyAddressRetrieve(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<UserAddressInfo>>
  public usersMyAddressRetrieve(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<UserAddressInfo>>
  public usersMyAddressRetrieve(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/my-address/`
    return this.httpClient.request<UserAddressInfo>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersOtpCreate(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<OTPRequestResponse>
  public usersOtpCreate(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<OTPRequestResponse>>
  public usersOtpCreate(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<OTPRequestResponse>>
  public usersOtpCreate(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/otp`
    return this.httpClient.request<OTPRequestResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersOtpVerifyCreate(
    requestParameters: UsersOtpVerifyCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public usersOtpVerifyCreate(
    requestParameters: UsersOtpVerifyCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public usersOtpVerifyCreate(
    requestParameters: UsersOtpVerifyCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public usersOtpVerifyCreate(
    requestParameters: UsersOtpVerifyCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const oTPVerifyRequestRequest = requestParameters.oTPVerifyRequestRequest
    if (oTPVerifyRequestRequest === null || oTPVerifyRequestRequest === undefined) {
      throw new Error(
        'Required parameter oTPVerifyRequestRequest was null or undefined when calling usersOtpVerifyCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/otp/verify`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: oTPVerifyRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * An Api ViewSet which provides a method to reset a password based on a unique token
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersPasswordResetConfirmCreate(
    requestParameters: UsersPasswordResetConfirmCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PasswordToken>
  public usersPasswordResetConfirmCreate(
    requestParameters: UsersPasswordResetConfirmCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PasswordToken>>
  public usersPasswordResetConfirmCreate(
    requestParameters: UsersPasswordResetConfirmCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PasswordToken>>
  public usersPasswordResetConfirmCreate(
    requestParameters: UsersPasswordResetConfirmCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const passwordTokenRequest = requestParameters.passwordTokenRequest
    if (passwordTokenRequest === null || passwordTokenRequest === undefined) {
      throw new Error(
        'Required parameter passwordTokenRequest was null or undefined when calling usersPasswordResetConfirmCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/password-reset/confirm/`
    return this.httpClient.request<PasswordToken>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: passwordTokenRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * An Api ViewSet which provides a method to request a password reset token based on an e-mail address  Sends a signal reset_password_token_created when a reset token was created
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersPasswordResetCreate(
    requestParameters: UsersPasswordResetCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Email>
  public usersPasswordResetCreate(
    requestParameters: UsersPasswordResetCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Email>>
  public usersPasswordResetCreate(
    requestParameters: UsersPasswordResetCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Email>>
  public usersPasswordResetCreate(
    requestParameters: UsersPasswordResetCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const emailRequest = requestParameters.emailRequest
    if (emailRequest === null || emailRequest === undefined) {
      throw new Error('Required parameter emailRequest was null or undefined when calling usersPasswordResetCreate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/password-reset/`
    return this.httpClient.request<Email>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: emailRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * An Api ViewSet which provides a method to verify that a token is valid
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersPasswordResetValidateTokenCreate(
    requestParameters: UsersPasswordResetValidateTokenCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<ResetToken>
  public usersPasswordResetValidateTokenCreate(
    requestParameters: UsersPasswordResetValidateTokenCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<ResetToken>>
  public usersPasswordResetValidateTokenCreate(
    requestParameters: UsersPasswordResetValidateTokenCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<ResetToken>>
  public usersPasswordResetValidateTokenCreate(
    requestParameters: UsersPasswordResetValidateTokenCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const resetTokenRequest = requestParameters.resetTokenRequest
    if (resetTokenRequest === null || resetTokenRequest === undefined) {
      throw new Error(
        'Required parameter resetTokenRequest was null or undefined when calling usersPasswordResetValidateTokenCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/password-reset/validate-token/`
    return this.httpClient.request<ResetToken>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: resetTokenRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersProfileChangePasswordCreate(
    requestParameters: UsersProfileChangePasswordCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public usersProfileChangePasswordCreate(
    requestParameters: UsersProfileChangePasswordCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public usersProfileChangePasswordCreate(
    requestParameters: UsersProfileChangePasswordCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public usersProfileChangePasswordCreate(
    requestParameters: UsersProfileChangePasswordCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const changePasswordRequest = requestParameters.changePasswordRequest
    if (changePasswordRequest === null || changePasswordRequest === undefined) {
      throw new Error(
        'Required parameter changePasswordRequest was null or undefined when calling usersProfileChangePasswordCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/profile/change-password`
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: changePasswordRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersProfileRetrieve(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<UserProfileGet>
  public usersProfileRetrieve(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<UserProfileGet>>
  public usersProfileRetrieve(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<UserProfileGet>>
  public usersProfileRetrieve(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/profile`
    return this.httpClient.request<UserProfileGet>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersProfileUpdate(
    requestParameters: UsersProfileUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<UserProfileGet>
  public usersProfileUpdate(
    requestParameters: UsersProfileUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<UserProfileGet>>
  public usersProfileUpdate(
    requestParameters: UsersProfileUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<UserProfileGet>>
  public usersProfileUpdate(
    requestParameters: UsersProfileUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const userProfileUpdateRequest = requestParameters.userProfileUpdateRequest
    if (userProfileUpdateRequest === null || userProfileUpdateRequest === undefined) {
      throw new Error(
        'Required parameter userProfileUpdateRequest was null or undefined when calling usersProfileUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/profile`
    return this.httpClient.request<UserProfileGet>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userProfileUpdateRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersRegisterWhenCheckoutUpdate(
    requestParameters: UsersRegisterWhenCheckoutUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<NewGTUserCheckoutRegister>
  public usersRegisterWhenCheckoutUpdate(
    requestParameters: UsersRegisterWhenCheckoutUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<NewGTUserCheckoutRegister>>
  public usersRegisterWhenCheckoutUpdate(
    requestParameters: UsersRegisterWhenCheckoutUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<NewGTUserCheckoutRegister>>
  public usersRegisterWhenCheckoutUpdate(
    requestParameters: UsersRegisterWhenCheckoutUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling usersRegisterWhenCheckoutUpdate.')
    }
    const newGTUserCheckoutRegisterRequest = requestParameters.newGTUserCheckoutRegisterRequest

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/register-when-checkout/`
    return this.httpClient.request<NewGTUserCheckoutRegister>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: newGTUserCheckoutRegisterRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersRegisterWithPhoneNumberUpdate(
    requestParameters: UsersRegisterWithPhoneNumberUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<NewGTUserPhoneOnly>
  public usersRegisterWithPhoneNumberUpdate(
    requestParameters: UsersRegisterWithPhoneNumberUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<NewGTUserPhoneOnly>>
  public usersRegisterWithPhoneNumberUpdate(
    requestParameters: UsersRegisterWithPhoneNumberUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<NewGTUserPhoneOnly>>
  public usersRegisterWithPhoneNumberUpdate(
    requestParameters: UsersRegisterWithPhoneNumberUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling usersRegisterWithPhoneNumberUpdate.')
    }
    const newGTUserPhoneOnlyRequest = requestParameters.newGTUserPhoneOnlyRequest
    if (newGTUserPhoneOnlyRequest === null || newGTUserPhoneOnlyRequest === undefined) {
      throw new Error(
        'Required parameter newGTUserPhoneOnlyRequest was null or undefined when calling usersRegisterWithPhoneNumberUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/register-with-phone-number/`
    return this.httpClient.request<NewGTUserPhoneOnly>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: newGTUserPhoneOnlyRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersRetrieve(
    requestParameters: UsersRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<User>
  public usersRetrieve(
    requestParameters: UsersRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<User>>
  public usersRetrieve(
    requestParameters: UsersRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<User>>
  public usersRetrieve(
    requestParameters: UsersRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling usersRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersTokenRefreshCreate(
    requestParameters: UsersTokenRefreshCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<TokenRefresh>
  public usersTokenRefreshCreate(
    requestParameters: UsersTokenRefreshCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<TokenRefresh>>
  public usersTokenRefreshCreate(
    requestParameters: UsersTokenRefreshCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<TokenRefresh>>
  public usersTokenRefreshCreate(
    requestParameters: UsersTokenRefreshCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const tokenRefreshRequest = requestParameters.tokenRefreshRequest
    if (tokenRefreshRequest === null || tokenRefreshRequest === undefined) {
      throw new Error(
        'Required parameter tokenRefreshRequest was null or undefined when calling usersTokenRefreshCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/token-refresh`
    return this.httpClient.request<TokenRefresh>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: tokenRefreshRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public usersUpdate(
    requestParameters: UsersUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<User>
  public usersUpdate(
    requestParameters: UsersUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<User>>
  public usersUpdate(
    requestParameters: UsersUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<User>>
  public usersUpdate(
    requestParameters: UsersUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling usersUpdate.')
    }
    const userRequest = requestParameters.userRequest
    if (userRequest === null || userRequest === undefined) {
      throw new Error('Required parameter userRequest was null or undefined when calling usersUpdate.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<User>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: userRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }
}
