import { IconModule } from '@shared/components/v1/icon/icon.component'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core'

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, IconModule],
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() buttonType:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'outline'
    | 'outline-white'
    | 'danger'
    | 'underline'
    | 'warning'
    | 'outline-bg-white' = 'primary'
  @Input() buttonSize: 'normal' | 'large' | 'full' | 'l-full' = 'normal'
  @Input() labelAlignment: 'justify-center' | 'justify-between' | 'justify-around' = 'justify-center'
  @Input() backgroundColor?: string
  @Input() label = 'Button'
  @Input() iconName = 'plus'
  @Input() showIconRight = false
  @Input({ transform: booleanAttribute }) showIconLeft = false
  @Input() iconSize = 16
  @Input() disabled = false
  @Input({ transform: booleanAttribute }) noHorizontalPadding = false
  @Input() class?: string
  @Output() clickButton = new EventEmitter<Event>()

  public get classes(): string[] {
    let type = ''
    let size = ''

    switch (this.buttonType) {
      case 'primary': {
        type = 'text-white bg-primary-blue border border-solid border-primary-blue'
        break
      }
      case 'secondary': {
        type = 'text-text-black bg-gray-100 border border-solid border-gray-100'
        break
      }
      case 'tertiary': {
        type = 'text-primary-blue bg-white border border-solid border-primary-blue'
        break
      }
      case 'warning': {
        type = 'text-[#FF5C00] bg-white border border-solid border-[#FF5C00]'
        break
      }
      case 'outline': {
        type = 'text-text-black border border-solid border-black'
        break
      }
      case 'outline-bg-white': {
        type = 'text-text-black border border-solid border-black bg-white'
        break
      }
      case 'outline-white': {
        type = 'text-white border border-solid border-white'
        break
      }
      case 'danger': {
        type = 'text-primary-red border border-solid border-primary-red'
        break
      }
      case 'underline': {
        type = 'underline'
        break
      }
      default: {
        break
      }
    }

    const px = this.noHorizontalPadding ? '' : 'px-4'
    switch (this.buttonSize) {
      case 'normal': {
        size = 'text-base py-2 w-52'
        break
      }
      case 'large': {
        size = 'text-lg py-2.5 w-52'
        break
      }
      case 'full': {
        size = 'text-base py-2 w-full'
        break
      }
      case 'l-full': {
        size = 'text-lg py-2.5 w-full'
        break
      }
      default: {
        break
      }
    }

    return ['font-sans', 'font-bold', 'rounded', 'cursor-pointer', 'inline-block', 'leading-none', size, px, type]
  }

  get labelClasses(): string[] {
    let alignment = ''

    switch (this.labelAlignment) {
      case 'justify-center':
        alignment = 'justify-center'
        break
      case 'justify-between':
        alignment = 'justify-between'
        break
      case 'justify-around':
        alignment = 'justify-around'
        break
    }
    return [alignment]
  }
}
