/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http'
import { CustomHttpParameterCodec } from '../encoder'
import { Observable } from 'rxjs'

// @ts-ignore
import { PaginatedGTSummaryDataList } from '../model/paginatedGTSummaryDataList'
// @ts-ignore
import { PaginatedWholeSaleUserListList } from '../model/paginatedWholeSaleUserListList'
// @ts-ignore
import { PaginatedWholesaleGeneralTradeList } from '../model/paginatedWholesaleGeneralTradeList'
// @ts-ignore
import { PatchedWholesaleProfilePostRequest } from '../model/patchedWholesaleProfilePostRequest'
// @ts-ignore
import { WholeSaleProfileGetProductPackageManagement } from '../model/wholeSaleProfileGetProductPackageManagement'
// @ts-ignore
import { WholeSaleUser } from '../model/wholeSaleUser'
// @ts-ignore
import { WholeSaleUserRequest } from '../model/wholeSaleUserRequest'
// @ts-ignore
import { WholesaleADataVSBDataFilterRequestRequest } from '../model/wholesaleADataVSBDataFilterRequestRequest'
// @ts-ignore
import { WholesaleCouponData } from '../model/wholesaleCouponData'
// @ts-ignore
import { WholesaleDateFilterRequestRequest } from '../model/wholesaleDateFilterRequestRequest'
// @ts-ignore
import { WholesaleGeneralTrade } from '../model/wholesaleGeneralTrade'
// @ts-ignore
import { WholesaleInvitationCodeJoinRequest } from '../model/wholesaleInvitationCodeJoinRequest'
// @ts-ignore
import { WholesaleNotification } from '../model/wholesaleNotification'
// @ts-ignore
import { WholesaleOpenDateUpdateRequest } from '../model/wholesaleOpenDateUpdateRequest'
// @ts-ignore
import { WholesaleProductPackageSaleFilterRequestRequest } from '../model/wholesaleProductPackageSaleFilterRequestRequest'
// @ts-ignore
import { WholesaleProfileGet } from '../model/wholesaleProfileGet'
// @ts-ignore
import { WholesaleProfileInvitationCode } from '../model/wholesaleProfileInvitationCode'
// @ts-ignore
import { WholesaleProfilePost } from '../model/wholesaleProfilePost'
// @ts-ignore
import { WholesaleProfilePostRequest } from '../model/wholesaleProfilePostRequest'
// @ts-ignore
import { WholesaleSaleSummary } from '../model/wholesaleSaleSummary'
// @ts-ignore
import { WholesaleSaleSummaryFilterRequestRequest } from '../model/wholesaleSaleSummaryFilterRequestRequest'
// @ts-ignore
import { WholesaleSaleSummaryFilters } from '../model/wholesaleSaleSummaryFilters'
// @ts-ignore
import { WholesaleShopType } from '../model/wholesaleShopType'
// @ts-ignore
import { WholesaleSimpleSaleData } from '../model/wholesaleSimpleSaleData'

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables'
import { Configuration } from '../configuration'

export interface WholesalesCloseDatesUpdateRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
  wholesaleOpenDateUpdateRequest: WholesaleOpenDateUpdateRequest
}

export interface WholesalesCreateRequestParams {
  wholesaleProfilePostRequest: WholesaleProfilePostRequest
}

export interface WholesalesDashboardAVsBCreateRequestParams {
  wholesaleADataVSBDataFilterRequestRequest: WholesaleADataVSBDataFilterRequestRequest
}

export interface WholesalesDashboardCouponCreateRequestParams {
  wholesaleDateFilterRequestRequest: WholesaleDateFilterRequestRequest
}

export interface WholesalesDashboardGtSummaryListRequestParams {
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  sortBy?: string
}

export interface WholesalesDashboardLeastProductSalesCreateRequestParams {
  wholesaleProductPackageSaleFilterRequestRequest: WholesaleProductPackageSaleFilterRequestRequest
}

export interface WholesalesDashboardSummaryCreateRequestParams {
  wholesaleSaleSummaryFilterRequestRequest: WholesaleSaleSummaryFilterRequestRequest
}

export interface WholesalesDashboardTopProductSalesCreateRequestParams {
  wholesaleProductPackageSaleFilterRequestRequest: WholesaleProductPackageSaleFilterRequestRequest
}

export interface WholesalesGeneralTradesAllListRequestParams {
  wholesaleId: string
}

export interface WholesalesGeneralTradesListRequestParams {
  wholesaleId: string
  /** * &#x60;PENDING&#x60; - Pending * &#x60;APPROVED&#x60; - Approved * &#x60;REJECTED&#x60; - Rejected * &#x60;CLOSED&#x60; - Closed */
  approved?: Array<'APPROVED' | 'CLOSED' | 'PENDING' | 'REJECTED'>
  /** A page number within the paginated result set. */
  page?: number
  /** Number of results to return per page. */
  pageSize?: number
  /** A search term. */
  search?: string
}

export interface WholesalesJoinViaCodeCreateRequestParams {
  wholesaleInvitationCodeJoinRequest: WholesaleInvitationCodeJoinRequest
}

export interface WholesalesNotificationRetrieveRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
}

export interface WholesalesPartialUpdateRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
  patchedWholesaleProfilePostRequest?: PatchedWholesaleProfilePostRequest
}

export interface WholesalesProfileProductPackageManagementRetrieveRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
}

export interface WholesalesQrImageRetrieveRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
}

export interface WholesalesRetrieveRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
}

export interface WholesalesUpdateRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
  wholesaleProfilePostRequest: WholesaleProfilePostRequest
}

export interface WholesalesUploadShopProfilePictureUpdateRequestParams {
  /** A unique integer value identifying this wholesale profile. */
  id: number
  picture: Blob
}

export interface WholesalesUsersCreateRequestParams {
  wholesaleId: string
  wholeSaleUserRequest: WholeSaleUserRequest
}

export interface WholesalesUsersDestroyRequestParams {
  /** A unique integer value identifying this wholesale user. */
  id: number
  wholesaleId: string
}

export interface WholesalesUsersInvitationCodeCreateRequestParams {
  wholesaleId: string
}

export interface WholesalesUsersInvitationCodeRetrieveRequestParams {
  wholesaleId: string
}

export interface WholesalesUsersListRequestParams {
  wholesaleId: string
  /** The pagination cursor value. */
  cursor?: string
  /** Number of results to return per page. */
  pageSize?: number
}

export interface WholesalesUsersRetrieveRequestParams {
  /** A unique integer value identifying this wholesale user. */
  id: number
  wholesaleId: string
}

@Injectable({
  providedIn: 'root',
})
export class WholesalesService {
  protected basePath = 'http://localhost'
  public defaultHeaders = new HttpHeaders()
  public configuration = new Configuration()
  public encoder: HttpParameterCodec

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0]
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath
      }
      this.configuration.basePath = basePath
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec()
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data'
    for (const consume of consumes) {
      if (form === consume) {
        return true
      }
    }
    return false
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value)
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key)
    }
    return httpParams
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        ;(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)))
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10))
        } else {
          throw Error('key may not be null if value is Date')
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        )
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value)
    } else {
      throw Error('key may not be null if value is not object or array')
    }
    return httpParams
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesCloseDatesUpdate(
    requestParameters: WholesalesCloseDatesUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileGet>
  public wholesalesCloseDatesUpdate(
    requestParameters: WholesalesCloseDatesUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileGet>>
  public wholesalesCloseDatesUpdate(
    requestParameters: WholesalesCloseDatesUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileGet>>
  public wholesalesCloseDatesUpdate(
    requestParameters: WholesalesCloseDatesUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesCloseDatesUpdate.')
    }
    const wholesaleOpenDateUpdateRequest = requestParameters.wholesaleOpenDateUpdateRequest
    if (wholesaleOpenDateUpdateRequest === null || wholesaleOpenDateUpdateRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleOpenDateUpdateRequest was null or undefined when calling wholesalesCloseDatesUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/close-dates/`
    return this.httpClient.request<WholesaleProfileGet>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleOpenDateUpdateRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesCreate(
    requestParameters: WholesalesCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfilePost>
  public wholesalesCreate(
    requestParameters: WholesalesCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfilePost>>
  public wholesalesCreate(
    requestParameters: WholesalesCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfilePost>>
  public wholesalesCreate(
    requestParameters: WholesalesCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleProfilePostRequest = requestParameters.wholesaleProfilePostRequest
    if (wholesaleProfilePostRequest === null || wholesaleProfilePostRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleProfilePostRequest was null or undefined when calling wholesalesCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/octet-stream;base64']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/`
    return this.httpClient.request<WholesaleProfilePost>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleProfilePostRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardAVsBCreate(
    requestParameters: WholesalesDashboardAVsBCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleSimpleSaleData>
  public wholesalesDashboardAVsBCreate(
    requestParameters: WholesalesDashboardAVsBCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleSimpleSaleData>>
  public wholesalesDashboardAVsBCreate(
    requestParameters: WholesalesDashboardAVsBCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleSimpleSaleData>>
  public wholesalesDashboardAVsBCreate(
    requestParameters: WholesalesDashboardAVsBCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleADataVSBDataFilterRequestRequest = requestParameters.wholesaleADataVSBDataFilterRequestRequest
    if (wholesaleADataVSBDataFilterRequestRequest === null || wholesaleADataVSBDataFilterRequestRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleADataVSBDataFilterRequestRequest was null or undefined when calling wholesalesDashboardAVsBCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/a-vs-b/`
    return this.httpClient.request<WholesaleSimpleSaleData>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleADataVSBDataFilterRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardCouponCreate(
    requestParameters: WholesalesDashboardCouponCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleCouponData>
  public wholesalesDashboardCouponCreate(
    requestParameters: WholesalesDashboardCouponCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleCouponData>>
  public wholesalesDashboardCouponCreate(
    requestParameters: WholesalesDashboardCouponCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleCouponData>>
  public wholesalesDashboardCouponCreate(
    requestParameters: WholesalesDashboardCouponCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleDateFilterRequestRequest = requestParameters.wholesaleDateFilterRequestRequest
    if (wholesaleDateFilterRequestRequest === null || wholesaleDateFilterRequestRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleDateFilterRequestRequest was null or undefined when calling wholesalesDashboardCouponCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/coupon/`
    return this.httpClient.request<WholesaleCouponData>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleDateFilterRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardFiltersRetrieve(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleSaleSummaryFilters>
  public wholesalesDashboardFiltersRetrieve(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleSaleSummaryFilters>>
  public wholesalesDashboardFiltersRetrieve(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleSaleSummaryFilters>>
  public wholesalesDashboardFiltersRetrieve(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/filters/`
    return this.httpClient.request<WholesaleSaleSummaryFilters>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardGtSummaryList(
    requestParameters: WholesalesDashboardGtSummaryListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedGTSummaryDataList>
  public wholesalesDashboardGtSummaryList(
    requestParameters: WholesalesDashboardGtSummaryListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedGTSummaryDataList>>
  public wholesalesDashboardGtSummaryList(
    requestParameters: WholesalesDashboardGtSummaryListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedGTSummaryDataList>>
  public wholesalesDashboardGtSummaryList(
    requestParameters: WholesalesDashboardGtSummaryListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const page = requestParameters.page
    const pageSize = requestParameters.pageSize
    const sortBy = requestParameters.sortBy

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'page')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }
    if (sortBy !== undefined && sortBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortBy, 'sort_by')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/gt-summary/`
    return this.httpClient.request<PaginatedGTSummaryDataList>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardLeastProductSalesCreate(
    requestParameters: WholesalesDashboardLeastProductSalesCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleSimpleSaleData>
  public wholesalesDashboardLeastProductSalesCreate(
    requestParameters: WholesalesDashboardLeastProductSalesCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleSimpleSaleData>>
  public wholesalesDashboardLeastProductSalesCreate(
    requestParameters: WholesalesDashboardLeastProductSalesCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleSimpleSaleData>>
  public wholesalesDashboardLeastProductSalesCreate(
    requestParameters: WholesalesDashboardLeastProductSalesCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleProductPackageSaleFilterRequestRequest =
      requestParameters.wholesaleProductPackageSaleFilterRequestRequest
    if (
      wholesaleProductPackageSaleFilterRequestRequest === null ||
      wholesaleProductPackageSaleFilterRequestRequest === undefined
    ) {
      throw new Error(
        'Required parameter wholesaleProductPackageSaleFilterRequestRequest was null or undefined when calling wholesalesDashboardLeastProductSalesCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/least-product-sales/`
    return this.httpClient.request<WholesaleSimpleSaleData>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleProductPackageSaleFilterRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardSummaryCreate(
    requestParameters: WholesalesDashboardSummaryCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleSaleSummary>
  public wholesalesDashboardSummaryCreate(
    requestParameters: WholesalesDashboardSummaryCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleSaleSummary>>
  public wholesalesDashboardSummaryCreate(
    requestParameters: WholesalesDashboardSummaryCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleSaleSummary>>
  public wholesalesDashboardSummaryCreate(
    requestParameters: WholesalesDashboardSummaryCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleSaleSummaryFilterRequestRequest = requestParameters.wholesaleSaleSummaryFilterRequestRequest
    if (wholesaleSaleSummaryFilterRequestRequest === null || wholesaleSaleSummaryFilterRequestRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleSaleSummaryFilterRequestRequest was null or undefined when calling wholesalesDashboardSummaryCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/summary/`
    return this.httpClient.request<WholesaleSaleSummary>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleSaleSummaryFilterRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesDashboardTopProductSalesCreate(
    requestParameters: WholesalesDashboardTopProductSalesCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleSimpleSaleData>
  public wholesalesDashboardTopProductSalesCreate(
    requestParameters: WholesalesDashboardTopProductSalesCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleSimpleSaleData>>
  public wholesalesDashboardTopProductSalesCreate(
    requestParameters: WholesalesDashboardTopProductSalesCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleSimpleSaleData>>
  public wholesalesDashboardTopProductSalesCreate(
    requestParameters: WholesalesDashboardTopProductSalesCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleProductPackageSaleFilterRequestRequest =
      requestParameters.wholesaleProductPackageSaleFilterRequestRequest
    if (
      wholesaleProductPackageSaleFilterRequestRequest === null ||
      wholesaleProductPackageSaleFilterRequestRequest === undefined
    ) {
      throw new Error(
        'Required parameter wholesaleProductPackageSaleFilterRequestRequest was null or undefined when calling wholesalesDashboardTopProductSalesCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/dashboard/top-product-sales/`
    return this.httpClient.request<WholesaleSimpleSaleData>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleProductPackageSaleFilterRequestRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesGeneralTradesAllList(
    requestParameters: WholesalesGeneralTradesAllListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<WholesaleGeneralTrade>>
  public wholesalesGeneralTradesAllList(
    requestParameters: WholesalesGeneralTradesAllListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<WholesaleGeneralTrade>>>
  public wholesalesGeneralTradesAllList(
    requestParameters: WholesalesGeneralTradesAllListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<WholesaleGeneralTrade>>>
  public wholesalesGeneralTradesAllList(
    requestParameters: WholesalesGeneralTradesAllListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error(
        'Required parameter wholesaleId was null or undefined when calling wholesalesGeneralTradesAllList.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/general-trades/all/`
    return this.httpClient.request<Array<WholesaleGeneralTrade>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesGeneralTradesList(
    requestParameters: WholesalesGeneralTradesListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedWholesaleGeneralTradeList>
  public wholesalesGeneralTradesList(
    requestParameters: WholesalesGeneralTradesListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedWholesaleGeneralTradeList>>
  public wholesalesGeneralTradesList(
    requestParameters: WholesalesGeneralTradesListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedWholesaleGeneralTradeList>>
  public wholesalesGeneralTradesList(
    requestParameters: WholesalesGeneralTradesListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error('Required parameter wholesaleId was null or undefined when calling wholesalesGeneralTradesList.')
    }
    const approved = requestParameters.approved
    const page = requestParameters.page
    const pageSize = requestParameters.pageSize
    const search = requestParameters.search

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (approved) {
      approved.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'approved')
      })
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'page')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }
    if (search !== undefined && search !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/general-trades/`
    return this.httpClient.request<PaginatedWholesaleGeneralTradeList>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesJoinViaCodeCreate(
    requestParameters: WholesalesJoinViaCodeCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileGet>
  public wholesalesJoinViaCodeCreate(
    requestParameters: WholesalesJoinViaCodeCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileGet>>
  public wholesalesJoinViaCodeCreate(
    requestParameters: WholesalesJoinViaCodeCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileGet>>
  public wholesalesJoinViaCodeCreate(
    requestParameters: WholesalesJoinViaCodeCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleInvitationCodeJoinRequest = requestParameters.wholesaleInvitationCodeJoinRequest
    if (wholesaleInvitationCodeJoinRequest === null || wholesaleInvitationCodeJoinRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleInvitationCodeJoinRequest was null or undefined when calling wholesalesJoinViaCodeCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/join-via-code/`
    return this.httpClient.request<WholesaleProfileGet>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleInvitationCodeJoinRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesNotificationRetrieve(
    requestParameters: WholesalesNotificationRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleNotification>
  public wholesalesNotificationRetrieve(
    requestParameters: WholesalesNotificationRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleNotification>>
  public wholesalesNotificationRetrieve(
    requestParameters: WholesalesNotificationRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleNotification>>
  public wholesalesNotificationRetrieve(
    requestParameters: WholesalesNotificationRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesNotificationRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/notification/`
    return this.httpClient.request<WholesaleNotification>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesPartialUpdate(
    requestParameters: WholesalesPartialUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfilePost>
  public wholesalesPartialUpdate(
    requestParameters: WholesalesPartialUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfilePost>>
  public wholesalesPartialUpdate(
    requestParameters: WholesalesPartialUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfilePost>>
  public wholesalesPartialUpdate(
    requestParameters: WholesalesPartialUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesPartialUpdate.')
    }
    const patchedWholesaleProfilePostRequest = requestParameters.patchedWholesaleProfilePostRequest

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/octet-stream;base64']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<WholesaleProfilePost>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: patchedWholesaleProfilePostRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesProfileProductPackageManagementRetrieve(
    requestParameters: WholesalesProfileProductPackageManagementRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholeSaleProfileGetProductPackageManagement>
  public wholesalesProfileProductPackageManagementRetrieve(
    requestParameters: WholesalesProfileProductPackageManagementRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholeSaleProfileGetProductPackageManagement>>
  public wholesalesProfileProductPackageManagementRetrieve(
    requestParameters: WholesalesProfileProductPackageManagementRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholeSaleProfileGetProductPackageManagement>>
  public wholesalesProfileProductPackageManagementRetrieve(
    requestParameters: WholesalesProfileProductPackageManagementRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wholesalesProfileProductPackageManagementRetrieve.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/profile-product-package-management/`
    return this.httpClient.request<WholeSaleProfileGetProductPackageManagement>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesQrImageRetrieve(
    requestParameters: WholesalesQrImageRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Blob>
  public wholesalesQrImageRetrieve(
    requestParameters: WholesalesQrImageRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Blob>>
  public wholesalesQrImageRetrieve(
    requestParameters: WholesalesQrImageRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Blob>>
  public wholesalesQrImageRetrieve(
    requestParameters: WholesalesQrImageRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesQrImageRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/qr-image/`
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: 'blob',
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesRetrieve(
    requestParameters: WholesalesRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileGet>
  public wholesalesRetrieve(
    requestParameters: WholesalesRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileGet>>
  public wholesalesRetrieve(
    requestParameters: WholesalesRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileGet>>
  public wholesalesRetrieve(
    requestParameters: WholesalesRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<WholesaleProfileGet>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesShopTypesList(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<Array<WholesaleShopType>>
  public wholesalesShopTypesList(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<Array<WholesaleShopType>>>
  public wholesalesShopTypesList(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<Array<WholesaleShopType>>>
  public wholesalesShopTypesList(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/shop-types/`
    return this.httpClient.request<Array<WholesaleShopType>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUpdate(
    requestParameters: WholesalesUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfilePost>
  public wholesalesUpdate(
    requestParameters: WholesalesUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfilePost>>
  public wholesalesUpdate(
    requestParameters: WholesalesUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfilePost>>
  public wholesalesUpdate(
    requestParameters: WholesalesUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesUpdate.')
    }
    const wholesaleProfilePostRequest = requestParameters.wholesaleProfilePostRequest
    if (wholesaleProfilePostRequest === null || wholesaleProfilePostRequest === undefined) {
      throw new Error(
        'Required parameter wholesaleProfilePostRequest was null or undefined when calling wholesalesUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json', 'application/octet-stream;base64']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<WholesaleProfilePost>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholesaleProfilePostRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUploadShopProfilePictureUpdate(
    requestParameters: WholesalesUploadShopProfilePictureUpdateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileGet>
  public wholesalesUploadShopProfilePictureUpdate(
    requestParameters: WholesalesUploadShopProfilePictureUpdateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileGet>>
  public wholesalesUploadShopProfilePictureUpdate(
    requestParameters: WholesalesUploadShopProfilePictureUpdateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileGet>>
  public wholesalesUploadShopProfilePictureUpdate(
    requestParameters: WholesalesUploadShopProfilePictureUpdateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wholesalesUploadShopProfilePictureUpdate.',
      )
    }
    const picture = requestParameters.picture
    if (picture === null || picture === undefined) {
      throw new Error(
        'Required parameter picture was null or undefined when calling wholesalesUploadShopProfilePictureUpdate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data']

    const canConsumeForm = this.canConsumeForm(consumes)

    let localVarFormParams: { append(param: string, value: any): any }
    let localVarUseForm = false
    let localVarConvertFormParamsToString = false
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm
    if (localVarUseForm) {
      localVarFormParams = new FormData()
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder })
    }

    if (picture !== undefined) {
      localVarFormParams = (localVarFormParams.append('picture', <any>picture) as any) || localVarFormParams
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/upload-shop-profile-picture/`
    return this.httpClient.request<WholesaleProfileGet>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersCreate(
    requestParameters: WholesalesUsersCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholeSaleUser>
  public wholesalesUsersCreate(
    requestParameters: WholesalesUsersCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholeSaleUser>>
  public wholesalesUsersCreate(
    requestParameters: WholesalesUsersCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholeSaleUser>>
  public wholesalesUsersCreate(
    requestParameters: WholesalesUsersCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error('Required parameter wholesaleId was null or undefined when calling wholesalesUsersCreate.')
    }
    const wholeSaleUserRequest = requestParameters.wholeSaleUserRequest
    if (wholeSaleUserRequest === null || wholeSaleUserRequest === undefined) {
      throw new Error(
        'Required parameter wholeSaleUserRequest was null or undefined when calling wholesalesUsersCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json']
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes)
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected)
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/`
    return this.httpClient.request<WholeSaleUser>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: wholeSaleUserRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersDestroy(
    requestParameters: WholesalesUsersDestroyRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any>
  public wholesalesUsersDestroy(
    requestParameters: WholesalesUsersDestroyRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<any>>
  public wholesalesUsersDestroy(
    requestParameters: WholesalesUsersDestroyRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<any>>
  public wholesalesUsersDestroy(
    requestParameters: WholesalesUsersDestroyRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesUsersDestroy.')
    }
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error('Required parameter wholesaleId was null or undefined when calling wholesalesUsersDestroy.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = []
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersInvitationCodeCreate(
    requestParameters: WholesalesUsersInvitationCodeCreateRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileInvitationCode>
  public wholesalesUsersInvitationCodeCreate(
    requestParameters: WholesalesUsersInvitationCodeCreateRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileInvitationCode>>
  public wholesalesUsersInvitationCodeCreate(
    requestParameters: WholesalesUsersInvitationCodeCreateRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileInvitationCode>>
  public wholesalesUsersInvitationCodeCreate(
    requestParameters: WholesalesUsersInvitationCodeCreateRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error(
        'Required parameter wholesaleId was null or undefined when calling wholesalesUsersInvitationCodeCreate.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/invitation-code/`
    return this.httpClient.request<WholesaleProfileInvitationCode>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersInvitationCodeRetrieve(
    requestParameters: WholesalesUsersInvitationCodeRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholesaleProfileInvitationCode>
  public wholesalesUsersInvitationCodeRetrieve(
    requestParameters: WholesalesUsersInvitationCodeRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholesaleProfileInvitationCode>>
  public wholesalesUsersInvitationCodeRetrieve(
    requestParameters: WholesalesUsersInvitationCodeRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholesaleProfileInvitationCode>>
  public wholesalesUsersInvitationCodeRetrieve(
    requestParameters: WholesalesUsersInvitationCodeRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error(
        'Required parameter wholesaleId was null or undefined when calling wholesalesUsersInvitationCodeRetrieve.',
      )
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/invitation-code/`
    return this.httpClient.request<WholesaleProfileInvitationCode>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersList(
    requestParameters: WholesalesUsersListRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<PaginatedWholeSaleUserListList>
  public wholesalesUsersList(
    requestParameters: WholesalesUsersListRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<PaginatedWholeSaleUserListList>>
  public wholesalesUsersList(
    requestParameters: WholesalesUsersListRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<PaginatedWholeSaleUserListList>>
  public wholesalesUsersList(
    requestParameters: WholesalesUsersListRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error('Required parameter wholesaleId was null or undefined when calling wholesalesUsersList.')
    }
    const cursor = requestParameters.cursor
    const pageSize = requestParameters.pageSize

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder })
    if (cursor !== undefined && cursor !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cursor, 'cursor')
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'page_size')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/`
    return this.httpClient.request<PaginatedWholeSaleUserListList>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    )
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wholesalesUsersRetrieve(
    requestParameters: WholesalesUsersRetrieveRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<WholeSaleUser>
  public wholesalesUsersRetrieve(
    requestParameters: WholesalesUsersRetrieveRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpResponse<WholeSaleUser>>
  public wholesalesUsersRetrieve(
    requestParameters: WholesalesUsersRetrieveRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<HttpEvent<WholeSaleUser>>
  public wholesalesUsersRetrieve(
    requestParameters: WholesalesUsersRetrieveRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext; transferCache?: boolean },
  ): Observable<any> {
    const id = requestParameters.id
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling wholesalesUsersRetrieve.')
    }
    const wholesaleId = requestParameters.wholesaleId
    if (wholesaleId === null || wholesaleId === undefined) {
      throw new Error('Required parameter wholesaleId was null or undefined when calling wholesalesUsersRetrieve.')
    }

    let localVarHeaders = this.defaultHeaders

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json']
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts)
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected)
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext()
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json'
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text'
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json'
      } else {
        responseType_ = 'blob'
      }
    }

    let localVarPath = `/v1/wholesales/${this.configuration.encodeParam({
      name: 'wholesaleId',
      value: wholesaleId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/users/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}/`
    return this.httpClient.request<WholeSaleUser>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress,
    })
  }
}
