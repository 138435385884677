import { Component, EventEmitter, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@shared/components/v1/icon/icon.component'

@Component({
  selector: 'app-close-button-header',
  standalone: true,
  imports: [CommonModule, IconModule],
  templateUrl: './close-button-header.component.html',
})
export class CloseButtonHeaderComponent {
  @Output()
  closeButtonClick = new EventEmitter<Event>()
}
