<button
  type="button"
  (click)="clickButton.emit($event)"
  [ngClass]="classes"
  [ngStyle]="{ 'background-color': backgroundColor }"
  [class]="'disabled:text-text-disabled disabled:bg-disabled disabled:border-disabled ' + class"
  [disabled]="disabled"
>
  <div class="flex flex-row gap-2 items-center" [ngClass]="labelClasses">
    <app-icon *ngIf="showIconLeft" [name]="iconName" [size]="iconSize"></app-icon>
    <div *ngIf="label">{{ label }}</div>
    <app-icon *ngIf="showIconRight" [name]="iconName" [size]="iconSize"></app-icon>
  </div>
</button>
