/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `B` - Banner * `CG` - Coupon Group * `L` - Link
 */
export type BannerTypeEnum = 'B' | 'CG' | 'L'

export const BannerTypeEnum = {
  B: 'B' as BannerTypeEnum,
  Cg: 'CG' as BannerTypeEnum,
  L: 'L' as BannerTypeEnum,
}
