import { ButtonComponent } from '@shared/components/v1/button/button.component'
import { booleanAttribute, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@shared/components/v1/icon/icon.component'
import { CloseButtonHeaderComponent } from '@shared/components/v1/close-button-header/close-button-header.component'

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconModule, CloseButtonHeaderComponent],
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() visible = false
  @Input({ transform: booleanAttribute }) showCloseHeader = false
  @Input() showCloseContent = false
  @Input() showHeader = true
  @Input() showFooter = true
  @Input() showCancel = true
  @Input() showConfirm = true
  @Input({ transform: booleanAttribute }) hasDetail = false
  @Input() header: string = 'กรุณาใส่หัวข้อ'
  @Input() contentRef?: TemplateRef<unknown>
  @Input() footerRef?: TemplateRef<unknown>
  @Input() leftButtonType: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'outline-white' | 'danger' | 'underline' =
    'danger'
  @Input() leftButtonText = 'ยกเลิก'
  @Input() rightButtonText = 'ยืนยัน'
  @Input() verticallyCentered = true
  @Output() closeModal = new EventEmitter()
  @Output() clickModal = new EventEmitter()

  get modalClasses(): string[] {
    return [
      'font-sans',
      'overflow-x-hidden',
      'top-0',
      'overflow-y-auto fixed',
      'inset-0',
      'z-50',
      'outline-none',
      'focus:outline-none',
      'w-full',
      'top-2/4',
      '-translate-y-2/4',
      'h-full',
    ]
  }

  get dialogClasses() {
    let dialogClasses: string[] = ['m-[1.5rem]']

    if (this.verticallyCentered) {
      dialogClasses = ['flex', 'justify-center', 'items-center', `min-h-[calc(100%-3rem)]`, ...dialogClasses]
    }
    return dialogClasses
  }

  close() {
    this.closeModal?.emit()
  }

  click() {
    this.clickModal?.emit()
  }
}
