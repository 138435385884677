import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-spinner-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner-overlay.component.html',
  styleUrl: './spinner-overlay.component.css',
})
export class SpinnerOverlayComponent {
  spinnerError = false

  handleError() {
    this.spinnerError = true
  }
}
