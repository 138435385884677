/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `PENDING_WS_CONFIRMATION` - Pending Ws Confirmation * `PENDING_GT_CONFIRMATION` - Pending Gt Confirmation * `PREPARING_ORDER` - Preparing Order * `OUT_FOR_DELIVERY` - Out For Delivery * `READY_FOR_PICK_UP` - Ready For Pick Up * `PENDING_GT_ACCEPT` - Pending Gt Accept * `SUCCESS` - Success * `CANCELLED` - Cancelled
 */
export type OrderStatusEnum =
  | 'PENDING_WS_CONFIRMATION'
  | 'PENDING_GT_CONFIRMATION'
  | 'PREPARING_ORDER'
  | 'OUT_FOR_DELIVERY'
  | 'READY_FOR_PICK_UP'
  | 'PENDING_GT_ACCEPT'
  | 'SUCCESS'
  | 'CANCELLED'

export const OrderStatusEnum = {
  PendingWsConfirmation: 'PENDING_WS_CONFIRMATION' as OrderStatusEnum,
  PendingGtConfirmation: 'PENDING_GT_CONFIRMATION' as OrderStatusEnum,
  PreparingOrder: 'PREPARING_ORDER' as OrderStatusEnum,
  OutForDelivery: 'OUT_FOR_DELIVERY' as OrderStatusEnum,
  ReadyForPickUp: 'READY_FOR_PICK_UP' as OrderStatusEnum,
  PendingGtAccept: 'PENDING_GT_ACCEPT' as OrderStatusEnum,
  Success: 'SUCCESS' as OrderStatusEnum,
  Cancelled: 'CANCELLED' as OrderStatusEnum,
}
